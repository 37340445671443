<template>
  <div>
    <div class="">
        <button class="inline-block group h-410 w-full mb-8 relative relative" @click="selecttab(1);hidebod();height();"><slot name="button" /></button>
      <div v-if="selected===1" class="fixed opacity-100 z-50 w-screen h-screen overflow-y-scroll lg:flex lg:items-center top-0 lg:-top-16 lg:top-0 left-0 right-0 bottom-0">
        <div class="absolute z-50 w-screen h-screen" @click="selecttab(0);hidebod();height();" style="filter: blur(2px);backdrop-filter: blur(10px);"></div>
        <div class="mx-auto relative z-50 bg-primary opacity-100 lg:rounded-xl modal-l px-12 py-8 lg:w-800 lg:h-450 overflow-hidden">
          <button type="button" name="button" class="inline-flex lg:mt-2 mb-4 gap-x-4 items-center font-light text-white group" @click="selecttab(0);hidebod();height();">
            <div class="text-white group-hover:text-primary group-hover:bg-white text-xl transition-all ease-in-out duration-300 p-1 z-10 border-white border">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
            Back
          </button>
          <div class="relative lg:grid lg:grid-cols-3 overflow-x-hidden overflow-y-scroll h-full pt-2 space-y-8 lg:space-y-0 lg:gap-x-12">
            <div>
              <slot name="images" />
            </div>
            <div class="lg:col-span-2 lg:order-first pb-16 sm:pb-2 lg:pr-12">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      selected: 0,
    }
  },
  methods: {
  selecttab(tab){
    this.selected = tab
  },
  hidebod () {
    this.isOpen = true
    document.querySelector('body').classList.toggle('overflow-hidden')
  },
  height () {
    this.isOpen = true
    document.querySelector('body').classList.toggle('max-h-screen')
  },
},
}
</script>
