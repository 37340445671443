<template>
  <div v-swiper:LogoSlider="settings">
    <div class="swiper-wrapper" style="transition-timing-function: linear;">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'LogoSlider',
  data () {
    return {
      settings: {
        slidesPerView: 2,
        direction: "horizontal",
        loop: true,

        spaceBetween: 40,
        autoplay: {
          delay: 0,
          disableOnInteraction: false,
        },
        speed: 3500,
        allowTouchMove: false,
        breakpoints: {
          650: {
            slidesPerView: 3,
            spaceBetween: 24,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 80,
          },
        }
      }
    }
  }
}
</script>
