<template>
  <validation-observer ref="form" v-slot="{ passes, invalid }">
    <div v-if="success" class="mb-8 border-2 text-primary border-primary font-lato p-4" role="alert">
      <div class="flex">
        <div class="py-1"><svg class="fill-current h-6 w-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
        <div>
          <p class="font-bold">Thank You!</p>
          <p class="text-sm">{{ success }}</p>
        </div>
      </div>
    </div>
    <div v-if="error" class="mb-8 border-2 text-white border-white font-lato bg-black p-4" role="alert">
      <div class="flex">
        <div class="py-1"><svg class="fill-current h-6 w-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
        <div>
          <p class="font-bold">Oops!</p>
          <p class="text-sm">{{ error }}</p>
        </div>
      </div>
    </div>
    <form  name="contact-form" class="grid gap-x-6 gap-y-3 text-left md:grid-cols-4" @submit.stop>
      <validation-provider v-slot="{ errors }" name="Firstname" rules="required" class="md:col-span-4">
        <div>

          <input
            id="form-firstname"
            placeholder="Name"
            v-model="form.firstname"
            :class="{ error: errors[0] }"
            type="text"
            class="input"
          />
          <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
        </div>
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Email" rules="required|email" class="md:col-span-4">
        <div>

          <input
            id="form-email"
            placeholder="Email"
            v-model="form.email"
            :class="{ error: errors[0] }"
            type="text"
            class="input"

          />
          <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
        </div>
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Company " class="md:col-span-4">
        <div>

          <input
              id="form-company"
              placeholder="Company "
              v-model="form.company"
              :class="{ error: errors[0] }"
              type="text"
              class="input"

          />
          <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
        </div>
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Phone" rules="required" class="md:col-span-4">
        <div>

          <input
              id="form-phone"
              placeholder="Phone "
              v-model="form.phone"
              :class="{ error: errors[0] }"
              type="text"
              class="input"

          />
          <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
        </div>
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="interested" class="md:col-span-4">
        <div>
          <input type="hidden" v-model="form.interested" name="interested" />
          <select
              class="form-input rounded-none text-primary bg-white input"
              name="interested"
              @change="onChange($event)"
              v-model="selectvalue"
              id="form-interested"
              :class="{ error: errors[0] }"
          >
            <option selected value="" disabled>I’m interested in…</option>
            <option value="Environmental-Services">Environmental Services</option>
            <option value="Traffic-Management-Services">Traffic Management Services</option>
            <option value="Rail-Services">Rail Services</option>
            <option value="Other">Other</option>
          </select>
          <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
        </div>
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Message" class="md:col-span-4">
        <div>
          <textarea
            placeholder="Message"
            id="form-message"
            v-model="form.message"
            :class="{ error: errors[0] }"
            class="text-area"
            rows="4"
          ></textarea>
          <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
        </div>
      </validation-provider>
      <div class="md:col-span-4">
        <button type="button" class="inline-flex gap-x-4 items-center hover:gap-x-6 hover:text-secondary text-primary transition-all ease-in-out" :disabled="invalid" @click="passes(SendMessage)">
          <span>Send Enquiry</span>
          <icon-arrow class=" w-6"></icon-arrow>
        </button>
      </div>
    </form>
  </validation-observer>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
export default {
  name: 'ContactForm',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      success: false,
      error: false,
      selectvalue: '',
      form: {
        interested: this.selectvalue,
      }
    }
  },
  methods: {
    onChange(event) {
      this.form.interested = this.selectvalue
    },
    Reset () {
      this.form = {} // Clear input fields.
      // this.errors.clear()
    },
    SendMessage () {
      this.loading = true
      this.$axios.post('/wp-json/icon/v1/contact', this.form)
        .then((response) => {
          this.form = {} // Clear input fields.
          this.$nextTick(() => this.$refs.form.reset())
          this.success = response.data.message
          this.error = false
          /*
          this.$notify({
            type: 'primary',
            showMethod: 'fade in right',
            message: response.data.message
          })*/
        })
        .catch((err) => {
          this.success = false
          this.error = err.response.data.message
          /*
          this.$notify({
            message: err.response.data.message
          })
          */
        })
        .then(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style>
  input:active{
    border:none!important;
  }
  input:focus-visible{
    outline-width: 0px;
  }
  textarea:focus-visible{
    outline-width: 0px;
  }
</style>
