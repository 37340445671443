<template>
  <div v-swiper:EmployeeSlider="settings">
    <div class="swiper-wrapper">
      <slot />
    </div>
    <div class="relative text-center top-0 fibre-pagination swiper-pagination"></div>
  </div>
</template>
<script>
export default {
  name: 'EmployeeSlider',
  data () {
    return {
      settings: {
        slidesPerView: 1,
        direction: "horizontal",
        loop: false,
        autoHeight: true,
        spaceBetween: 40,
        noSwiping: false,
        speed: 1500,
        pagination: {
          clickable: true,
          el: '.fibre-pagination',
        },
        allowTouchMove: true,
      }
    }
  }
}
</script>
