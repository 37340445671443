<template>
  <div v-swiper:ReviewSliders="settings">
    <div class="swiper-wrapper">
      <slot />
    </div>
    <div class="swiper-scrollbar mt-8" style="max-height: 5px; overflow: hidden;">
      <div class="line bg-primary" style="margin-bottom:-5px;"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ReviewSliders',
  data () {
    return {
      settings: {
        slidesPerView: 1,
        direction: "horizontal",
        loop: false,
        spaceBetween: 40,
        noSwiping: false,
        speed: 1500,
        autoplay: {
          delay: 3000,
        },
        scrollbar: {
          el: '.swiper-scrollbar',
          draggable: true,
        },
        allowTouchMove: true,
      }
    }
  }
}
</script>
