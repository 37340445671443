<template>
  <div>
    <div class="lg:grid lg:grid-cols-3 lg:gap-x-12 lg:mt-12 mt-8 space-y-6 lg:space-y-0">
      <button class="hover:grayscale-0 grayscale w-full lg:w-auto transition-all ease-in-out duration-300" :class="{'grayscale-0': selected === 1}" @click="selecttab(1)"><slot name="buttonone" /></button>
      <button class="hover:grayscale-0 grayscale w-full lg:w-auto transition-all ease-in-out duration-300" :class="{'grayscale-0': selected === 2}" @click="selecttab(2)"><slot name="buttontwo" /></button>
      <button class="hover:grayscale-0 grayscale w-full lg:w-auto transition-all ease-in-out duration-300" :class="{'grayscale-0': selected === 3}" @click="selecttab(3)"><slot name="buttonthree" /></button>
    </div>
    <div v-show="selected===1">
      <slot name="environmental" />
    </div>
    <div v-show="selected===2">
      <slot name="traffic" />
    </div>
    <div v-show="selected===3">
      <slot name="rail" />
    </div>
  </div>
</template>
<script>
export default {
data () {
  return {
    selected: 1,
  }
},
methods: {
  selecttab(tab){
    this.selected = tab
  }
}
}
</script>
