<template>
  <div id="nav" class="relative navig z-30">
    <div class="bg-primary hidden lg:block py-4 relative z-40 w-screen px-8">
      <div class="container">
        <slot name="topnav" />
      </div>
    </div>
    <div id="addc" class="absolute top-0 left-0 right-0 bottom-0">

    </div>
    <div class="border-nav w-screen overflow-x-clip relative z-40 px-8 transition-all ease-in-out duration-300">
      <div class="container relative back transition-all ease-in-out duration-300">
        <div class="md:w-full relative flex justify-between items-center lg:gap-x-12">
          <div class="relative mb-0">
            <slot name="brand" />
          </div>
          <div class="h-6 lg:hidden">
            <button name="main menu" aria-label="main menu" type="button" @click="isMenu = !isMenu; isActive = !isActive; isSlid = true; isSlid = true;addo();logoh();addh();addomt();" class="text-center text-primary toggle-me">
              <div id="nav-icon4" :class="{ open: isActive }">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
          </div>
          <div class="hidden h-full lg:block" style="min-height: 98px;">
            <slot name="mainmenu" />
          </div>
        </div>
      </div>
    </div>
    <div :class="{ desktopmenu: isActive }"  class="absolute bg-primary overflow-x-hidden opacity-0 z-30 w-screen block min-h-screen md:h-screen top-0 right-0 left-0 bottom-0 invisi">
      <div class="absolute top-0 bottom-0 w-screen lg:w-505 bg-primary h-full right-0 lg:opacity-90"></div>
      <div class="relative min-h-screen md:h-screen w-screen lg:w-505 flex justify-center items-start ml-auto mr-0 z-10" style="min-height: 650px;">
        <div class="mx-auto w-308 pt-24 overflow-y-scroll pb-32">
          <slot name="mobilemenu" />
          <hr class="border-secondary w-1/2 mx-auto my-6">
          <slot name="topnav" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isOpen: false,
      isOpenLeft: false,
      services:false,
      isActive: false,
      isSlid: true,
      isMenu: false,
    }
  },
  methods: {
    addo () {
      document.querySelector('body').classList.toggle('overflow-hidden')
    },
    addom () {
      document.querySelector('.desktopmenu').classList.toggle('offscreen')
    },
    addomt () {
      document.querySelector('.invisi').classList.toggle('onscreen')
    },
    logoh () {
      document.querySelector('.border-nav').classList.toggle('bg-primary')
    },
    addh () {
      document.querySelector('body').classList.toggle('max-h-screen')
    },
    removeo () {
      document.querySelector('body').classList.toggle('overflow-hidden')
    },
    removeh () {
      document.querySelector('body').classList.toggle('max-h-screen')
    },
  }
}
</script>
<style lang="scss">
.moverin{
  right: -101vw;
}
.onscreen{
  right: 0!important;
}
.offscreen .mover{
  right: -101vw!important;
}
.invisi{
  margin-left: 105vw;
  transition: ease-in-out 0.5s all;
}
.invisit{
  margin-right: -105vw;
  transition: ease-in-out 0.5s all;
}
.invisib{
  margin-right: -200vw;
  transition: ease-in-out 0.5s all;
}
.desktopmenu{
  margin-left: 0;
  opacity: 1!important;
}
.slideit{
  animation: slideit 1.5s;
}

@keyframes slideit {
  0% {
    margin-right: -105vw;
  }
  30% {
    margin-left: 0;
  }
  70%{
    margin-left: 0;
  }
  100% {
    margin-right: 105vw;
  }
}
</style>
