<template>
  <div v-swiper:AccSlider="settings">
    <div class="swiper-wrapper" style="transition: linear;">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'AccSlider',
  data () {
    return {
      settings: {
        spaceBetween: 40,
        effect: 'slide',
        loop: true,
        freeMode: true,
        allowTouchMove: false,
        slidesPerView: 2,
        speed: 4000,
        autoplay: {
          delay: 1,
        },
        breakpoints: {
          650: {
            slidesPerView: 3,
            spaceBetween: 24,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 80,
          },
        }
      }
    }
  }
}
</script>
