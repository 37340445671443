<template>
  <div v-swiper:ReviewSlider="settings">
    <div class="swiper-wrapper">
      <slot />
    </div>
    <div class="swiper-scrollbar mt-8">
      <div class="line bg-primary" style="margin-bottom:-5px;"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LogosSlider',
  data () {
    return {
      settings: {
        slidesPerView: 2,
        direction: "horizontal",
        loop: false,
        spaceBetween: 20,
        noSwiping: false,
        autoplay: {
          delay: 2000,
        },
        scrollbar: {
          el: '.swiper-scrollbar',
          draggable: true,
          dragSize: 100.
        },
        allowTouchMove: true,
      }
    }
  }
}
</script>
