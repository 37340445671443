import Vue from 'vue'
window.Vue = Vue
import VueAnimate from 'vue-animate-scroll'
Vue.use(VueAnimate)
import $ from "jquery";
import axios from 'axios'
import { extend } from 'vee-validate'
import { required, email, regex } from 'vee-validate/dist/rules'
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)

import AppFixedHeader from './components/AppFixedHeader'
import ProjectSlider from './components/ProjectSlider'
import ReviewSlider from './components/ReviewSlider'
import EmployeeSlider from './components/EmployeeSlider'
import ReviewSliders from './components/ReviewSliders'
import LogoSlider from './components/LogoSlider'
import ServiceSlider from './components/ServiceSlider'
import LogosSlider from './components/LogosSlider'
import AccSlider from './components/AccSlider'
import LogostSlider from './components/LogostSlider'
import Modal from './components/Modal'
import Tabs from './components/Tabs'
import Modals from './components/Modals'
import AppNavBar from './components/AppNavBar'
import ContactForm from './components/ContactForm'
Vue.component('AppFixedHeader', AppFixedHeader)
Vue.component('AppNavBar', AppNavBar)
Vue.component('Modal', Modal)
Vue.component('Modals', Modals)
Vue.component('Tabs', Tabs)
Vue.component('EmployeeSlider', EmployeeSlider)
Vue.component('ReviewSlider', ReviewSlider)
Vue.component('ReviewSliders', ReviewSliders)
Vue.component('ProjectSlider', ProjectSlider)
Vue.component('LogoSlider', LogoSlider)
Vue.component('ServiceSlider', ServiceSlider)
Vue.component('AccSlider', AccSlider)
Vue.component('LogosSlider', LogosSlider)
Vue.component('LogostSlider', LogostSlider)
Vue.component('ContactForm', ContactForm)

const files = require.context('./components/icons/', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))


Vue.prototype.$axios = axios
Vue.config.productionTip = false


extend('required', {
  ...required,
  message: 'The {_field_} is required'
})

extend('email', email)
extend('regex', regex)

extend('min', {
  validate (value, args) {
    return value.length >= args.length
  },
  params: ['length'],
  message: 'The {_field_} field must have at least {length} characters'
})

extend('max', {
  validate (value, args) {
    return value.length <= args.length
  },
  params: ['length'],
  message: 'The {_field_} field may not be greater than {length} characters'
})

extend('length', {
  validate (value, args) {
    return value.length <= args.length
  },
  params: ['length'],
  message: 'The {_field_} field can have only {length} of checked items'
})
$(document).ready(function(){
  $('#nav-icon4').click(function(){
    $(this).toggleClass('open');
  });
  $('.accordion-list > li > .clickavle > .answer').hide();
  $('.accordion-list > li > .answer').hide();
  $('.accordion-list > li > .clickavle > .question').click(function() {
    var data_ids = $(this).data('action');
      if($(this).parents('li').hasClass("active")){
        $(this).parents('li').removeClass("active").find(".answer").slideUp();
      }else{
        $('.accordion-list > li.active').removeClass("active").find(".answer").slideUp();
        $(this).parents('li').addClass("active").find(".answer").slideDown();
        setTimeout(function (){
          $('html, body').animate({
            scrollTop: $("#"+data_ids).offset().top - 30
          }, 500);
        }, 500);
      }
    return false;
  });
  $('.myForm').on('submit', function() {
    $('html, body').animate({
      scrollTop: $("#result").offset().top
    }, 2000);
    return false;
  });
  $(".service").click(function() {
    var data_id = $(this).data('action');
    $('.accordion-list > li').removeClass('active');
    $("#"+data_id).addClass('active');
    $('html, body').animate({
      scrollTop: $("#"+data_id).offset().top - 30
    }, 2000);
  });
  $.fn.extend({
    qcss: function(css) {
      return $(this).queue(function(next) {
        $(this).css(css);
        next();
      });
    }
  });
  (function() {

    var quotes = $(".quotes");
    var quoteIndex = -1;

    function showNextQuote() {
      ++quoteIndex;
      quotes.eq(quoteIndex % quotes.length)
          .removeClass('shown')
          .fadeIn(1000)
          .addClass('shown')
          .delay(3000)
          .fadeOut(1000, showNextQuote);
    }

    showNextQuote();

  })();
});
